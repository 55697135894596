* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body {
    height: 100vh;
    margin: 0;
    padding: 0;
    font-weight: normal;
    color: #040316;
}

body {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
}

input {
    border: none;
    outline: none;
    border-radius: 0;
    width: 100%;
    border-bottom: 1px solid #dddd;
    font-size: 14px;
    color: #00264c;
}

/* -------------Payment Form------------------ */
.payment-form-container {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    position: fixed;
    padding: 20px;
    background-image: url("../media/image/payment_form_img.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    font-family: 'Roboto', sans-serif;
}

.payment-form {
    width: 40%;
    margin: 0 auto;
    box-shadow: 4px 4px 55px 0px rgba(0, 0, 0, 0.06);
    padding: 20px 30px;
    text-align: left;
    height: 80vh;
    border-radius: 20px;
    background-color: #fff;
}

.payment-details-heading{
    font-size: 25px;
}

.top-logo {
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.top-logo img {
    width: 25%;
}

.top-logo p {
    font-size: 12px;
    white-space: nowrap;
    color: #888;
}

.card-info-heading {
    color: #003366;
    font-size: 20px;
    font-weight: 600;
}

.payment-details h3 {
    color: #003366;
    font-weight: 600;
}

.card-info-para {
    font-size: 12px;
    color: rgba(136, 136, 136, 0.8);
    white-space: nowrap;
    margin-top: 0;
    margin-bottom: 10px;
}

.payment-details {
    text-align: center;
    margin-top: 10px;
}

.customer-details{
    margin-top: 10px;
}

.customer-details .form-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    margin: 5% 0;
}

.card-info-div {
    margin: 5% 0;
}

.card-info-details {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 2% 0;
}

.card-info-head {
    font-size: 16px;
    color: rgba(0, 51, 102, 0.8);
    font-weight: 600;
}

.mastercardimg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 100%;
}

.mastercardimg img {
    width: 100%;
    height: 100%;
    margin-top: -6px;
}

.card-number-input {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}

.expiry-date-input {
    display: flex;
    align-items: center;
    gap: 5px;
    color: rgba(136, 136, 136, 0.8);
    width: 100%;
}

.expirymonth {
    text-align: right;
}

.separator {
    margin-bottom: 10px;
    font-size: 20px;
}

.card-number-input .card-icon {
    position: absolute;
    top: 10%;
    right: 0;
}

.card-holder-input {
    width: 90%;
}

.submit-btn button {
    width: 100%;
    outline: 0;
    border: 0;
    height: 100%;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #002d70;
    color: #fff;
    border-radius: 8px;
    gap: 5px;
    cursor: pointer;
}

.card-details-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.certificates {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1%;
}

.certificates img {
    width: 30%;
}

@media (max-width: 768px) {
    .payment-form {
        box-sizing: border-box;
        width: 93%;
        height: fit-content;
    }

    .payment-form-head h3{
        font-size: 5vw;
    }

    .top-logo img {
        width: 40%;
    }
    
    .top-logo p {
        font-size: 3vw;
    }

    .card-info-heading {
        font-size: 4vw;
    }

    .card-info-head {
        font-size: 3vw;
    }

    .card-info-para {
        font-size: 2.5vw;
        margin-bottom: 20px;
    }

    .customer-details{
        margin-top: 5%;
    }
    .customer-details .form-group {
        margin: 20px 0;
    }

    .payment-details Input {
        font-size: 2.5vw;
    }

    .submit-btn button {
        font-size: 3vw;
    }

    .submit-btn svg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        margin-top: -5px;
    }

    .payment-failed-container {
    height: 60vh;
    }
    
    .payment-success-container {
     height: 60vh;
    }
    
    .payment-process-container{
       height: 60vh;
    }

}

@media (min-width: 769px) and (max-width: 1024px) {
    .payment-form {
        box-sizing: border-box;
        width: 93%;
    }

    .payment-form-head h3{
        font-size: 5vw;
    }

    .top-logo img {
        width: 40%;
    }
    
    .top-logo p {
        font-size: 3vw;
    }

    .card-info-heading {
        font-size: 4vw;
    }

    .card-info-head {
        font-size: 3vw;
    }

    .card-info-para {
        font-size: 2.5vw;
        margin-bottom: 20px;
    }

    .customer-details{
        margin-top: 5%;
    }
    .customer-details .form-group {
        margin: 40px 0;
    }

    .payment-details Input {
        font-size: 2.5vw;
    }

    .submit-btn button {
        font-size: 3vw;
    }

    .submit-btn svg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        margin-top: -5px;
    }
}


/* ----------------Payment Fail------------------------- */

.payment-failed-container {
    padding: 50px;
}

.payment-success-container {
    background-color: #00b772;
    padding: 50px;
}

.payment-process-container{
    background-color: #2bb6ff;
    padding: 50px;
}

.logo-header {
    text-align: center;
    height: 20%;
}

.logo {
    width: 40%;
}

.logo-header h2 {
    font-size: 16px;
    color: #003366;
    font-weight: 400;
}

.logo-header-sub-div {
    position: relative;
}

.tag-line{
    color: #ffffff !important;
}

.payment-form-modal-content {
    text-align: center;
    height:60%;
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
}

.failed-text{
    font-size: 24px;
    color: #ff4d4f;
    margin-bottom: 10px;
    font-weight: bold;
}

.retry-text {
    font-size: 16px;
    color: #8c8c8c;
}

.failpayment-image {
    margin: 0 auto;
    width: 60%;
    position: relative;
}

.failed-icon {
    width: 35%;
    margin-bottom: 20px;
}

.alertimg-gif {
    position: absolute;
    width: 20%;
    left: 25%;
    top: 45%;
}

.payment-form-footer {
    display: flex;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    width: 70%;
    height: 20%;
}

.success-gif{
    width: 100%;
}

.go-back-button {
    width: 100%;
    background-color: #ffffff;
    color: #003366;
    border: 1px solid #003366;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;
}

.go-back-button:hover {
    background-color: #003366;
    color: #ffffff;
}

.backbtn-completepayment{
    color: #0066FF;
}
